<script>
import { Bar } from 'vue-chartjs'
export default {
  extends: Bar,
  props : ['chartdata','options'],
  data: () => ({
  }),
 async mounted () {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>